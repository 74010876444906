import React from 'react'
import heartIconSvg from '../images/heart_icon.svg'

const HeartIcon = () => (
  <span>
    <img style={{ width: '1.4rem' }} src={heartIconSvg} alt="Heart icon" />
  </span>
)

const Footer = () => (
  <footer
    className="container my-typist56 mb-md-typist160 mt-md-typist96 mt-xl-typist160 mb-xl-typist240"
    id="footer"
  >
    <div className="lh-typist32 text-left text-sm-center small">
      <p>Typist&trade; Inc. - All rights reserved</p>
      <p>
        Made with <HeartIcon></HeartIcon> in Toronto, Canada. Hope you enjoy
        using Typist :)
      </p>
      <p>
        Please write&nbsp;
        <a href="mailto:typistcanada@gmail.com">typistcanada@gmail.com</a> with
        any questions/comments.
      </p>
    </div>
    <div className="row d-none">
      <div className="col-12 col-sm-6 col-lg-3 mb-4 d-flex flex-column">
        <div className="fs-4 fw-bold mb-typist24">Contact</div>
        <div className="mb-typist32 small">
          <a href="mailto:support@typistapp.ca">support@typistapp.ca</a>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-lg-3 mb-4 d-flex flex-column">
        <div className="fs-4 fw-bold mb-typist24">Account</div>
        <div className="d-flex">
          <div className="mb-typist32 small">Sign in</div>
          <div className="mb-typist32 ms-typist32 small">Register</div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer

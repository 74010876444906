import React from 'react'
import logoSvg from '../images/logo.svg'

const Header = () => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bg-typist-light py-typist16">
        <div className="container">
          <a href="/" className="navbar-brand" aria-current="page">
            <img src={logoSvg} alt="Typist Logo" style={{ width: 157 }} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            id="navbarSupportedContent"
            className="collapse navbar-collapse pb-3 pb-lg-0"
          >
            <div className="ms-auto d-flex flex-column flex-lg-row justify-content-start align-items-center">
              <a
                className="link-dark my-typist16 my-lg-0 small text-decoration-none"
                href="https://typistapp.ca/#/create_account"
              >
                Create Account
              </a>
              <a
                className="link-dark my-typist16 my-lg-0 ms-0 ms-lg-typist16 small text-decoration-none"
                href="https://typistapp.ca/#/welcome"
              >
                Sign In
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
